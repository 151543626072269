<template>
    <footer>
  <svg class="footsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path class="colr" fill-opacity="1" d="M0,224L17.1,202.7C34.3,181,69,139,103,138.7C137.1,139,171,181,206,202.7C240,224,274,224,309,218.7C342.9,213,377,203,411,202.7C445.7,203,480,213,514,213.3C548.6,213,583,203,617,181.3C651.4,160,686,128,720,144C754.3,160,789,224,823,245.3C857.1,267,891,245,926,234.7C960,224,994,224,1029,229.3C1062.9,235,1097,245,1131,218.7C1165.7,192,1200,128,1234,106.7C1268.6,85,1303,107,1337,122.7C1371.4,139,1406,149,1423,154.7L1440,160L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
  </svg>
    <div class="footerbg text-center">
      <div class="container">


      <div class="d-sm-flex justify-content-between align-items-center">
        <a target="_blank" href="//www.dmca.com/Protection/Status.aspx?ID=cef73c76-e364-4bb7-bb74-64b1be318b4d" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca-badge-w200-5x1-05.png?ID=cef73c76-e364-4bb7-bb74-64b1be318b4d"  alt="DMCA.com Protection Status" /></a>
        <a href="https://cloudflare.com" target="_blank"><img style="width:200px" src="../assets/cloudflarelogo.png"></a>
      </div>
      
      
      <br><br>
      <br><br><h5 class="ltext" id="colgr">phoenixbot.cc <i class="fab fa-phoenix-framework"></i></h5><br>
      <p class="fejllinkhov">Fejlesztő: <a href="https://mrtndev.xyz" target="_blank" class="fejllink">MrtnDev</a></p>
    </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
#colgr {
  background: linear-gradient(to bottom right, #ffee00 0%, #ff1e00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

footer {
  text-align: center;
  margin-bottom: 0;
}

.footerbg {
  background: #292b2c;
  padding:20px;
  margin-top:10px;
}

.colr {
  fill: #292b2c;
}

.uppervg {
  margin-top:-20px;
}
.footsvg {
  margin-bottom:-30px;
}

.fejllink {
    text-decoration: none;
    background: linear-gradient(to bottom right, #ffee00 0%, #ff1e00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% auto;
    animation: fejlanim 10s infinite;
}
.fejllinkhov {
    width: fit-content;
    margin: auto;
}
.fejllinkhov:hover {
    border-bottom: 1px solid gold;
}

@keyframes fejlanim {
    to {
    background-position: 500% center;
}
}




</style>
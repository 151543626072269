<template>
    <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container">
            <a href="#" class="navbar-brand">Phoenix <i class="fab fa-phoenix-framework"></i></a>

            <button class="navbar-toggler" type="button" aria-haspopup="true" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#navmenu"><span class="navbar-toggler-icon"></span></button>

            <div class="collapse navbar-collapse" id="navmenu">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Főoldal <i class="fas fa-home"></i></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/cmd" class="nav-link">Parancsok <i class="fas fa-terminal"></i></router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-on:click="loginErr()">Bejelentkezés <i class="fab fa-discord"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <section>
        <div class="container">
            <div id="msgs" v-on:click="minotis()">
        </div>
        </div>
    </section>
</template>

<script>
let notis = 0;

export default {
  name: 'Nav',
  methods: {
      loginErr(){
          if(notis == 4){
              //console.log('Max 4 értesítés');
          } else {
          document.getElementById('msgs').innerHTML += `<div><br><label onmouseover='this.style.background="rgba(255, 255, 255, 0.6)"' onmouseout='this.style.background="rgba(255, 255, 255, 0.445)"' onclick="this.parentElement.style.display='none';" style="background: rgba(255, 255, 255, 0.445);width: 300px;padding: 5px;padding-right: 20px;padding-left: 20px;padding-right: 20px;color:black;"><b>Hiba!</b><p class="xcon msgclose" style="cursor:pointer;z-index: 50;float:right;">&times;</p><p>A kezdőfelület jelenleg fejlesztés alatt áll</p></label><br></div>`;
        notis++;
          }
      },
      minotis(){
          notis-=1;
          console.log(notis);
      }
  }
}
</script>

<style scoped>
.circl {
 border-radius: 50%;
} 
.headimg {
    width:50px
}

.msgxd {
    background: rgba(255, 255, 255, 0.445);
    width: fit-content;
    width: 300px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px;
}
#msgs {
    overflow: auto;
    position:fixed;
    float: right;
    top:10;
    z-index: 5;
}

.xcon {
    float:right;
}

.msgclose {
    cursor: pointer;
}

.nav-link {
    cursor:pointer;
}

</style>

<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@900&display=swap');
  body {
    margin: 0;
    color:white;
  }

.ltext {
  font-family: 'Spartan', sans-serif;
}

::-moz-selection { /* Code for Firefox */
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.349);
  }
  
  ::selection {
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.349);
  }

  body {
    background: linear-gradient(to bottom right, #ffee00 0%, #ff1e00 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    /*height: 712px;
    width: 100%;*/
  }
</style>
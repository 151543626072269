<template>
  <div class="backgr">
    <Nav></Nav>
    <div>
      <section class="text-light p-5 text-center">
         <div class="container">
             <div>
                 <div class="centdiv">
                     <h1 class="ltext bt" id="colgr">Phoenix <i class="fab fa-phoenix-framework"></i></h1>
                     <a href="https://discord.com/api/oauth2/authorize?client_id=866425040353624074&permissions=8&scope=bot%20applications.commands" target="_blank" class="btn btn-secondary">Meghívás <i class="far fa-envelope"></i></a>&nbsp;&nbsp;<router-link to="/cmd" class="btn btn-secondary">Parancsok <i class="fas fa-terminal"></i></router-link>
                 </div>
             </div>
         </div>
    </section>
    </div><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  </div><svg class="uppervg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path class="colr" fill-opacity="1" d="M0,224L80,224C160,224,320,224,480,202.7C640,181,800,139,960,149.3C1120,160,1280,224,1360,256L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
</svg>
<br><br>
<br>

<section class="rolam">
<div class="container">
  <h1 class="ltext">Információk</h1>
  <p>A bot tárol adatokat. Nem kell megijedni, ezek az adatok kizárólak analitikai célokra vannak használva. Amint meghívod a bot-ot a szerveredre a szervered ID-ja el lesz mentve az adatbázisba, hogy számon tudjuk tartani az összes szervert amiben bent volt/amiben jelenleg is bent van a bot. Akármelyik parancs lefuttatásáról értesülünk, hogyha netán egy parancs hibája miatt leállna a bot.</p><br>
  <br>
  <h1 class="ltext">Support szerver</h1>
  <a href="https://discord.gg/ycgnEDQeVw" class="mcst ltext">Csatlakozás</a>
  <iframe src="https://discord.com/widget?id=865692276714110986&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
  <a href="https://discord.gg/ycgnEDQeVw" class="csatlkbtn ltext frcsatlbtn">Csatlakozás</a>
  <br>
</div>
</section>
<Footer/>
</template>

<script>
// @ is an alias to /src
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue"


export default {
  name: 'Home',
  components: {
    Nav,
    Footer
  }
}
</script>

<style scoped>
.backgr {
  background: #292b2c;
}

.colr {
  fill: #292b2c;
}

.bt {
  font-size: 55px;
}

#colgr {
  background: linear-gradient(to bottom right, #ffee00 0%, #ff1e00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rolam {
  margin-top: -50px;
}

.centdiv {
  position: absolute;
  width: 350px;
  z-index: 0;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}


.button2 {
  display: block;
  width: 30%;
  border: none;
  background-color: #292b2c;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.button2:hover {
  background-color: #393c3d;
}

.btna {
  text-decoration: none;
  color:white;
}


.csatlkbtn {
  color:white;
  text-decoration: none;
  background: #dddddd31;
  padding: 30px;
  padding-top: 35px;
  width: 30%;
  font-size:25px;
  text-align: center;
  border-radius: 0px;
  border: 2px solid #393c3d;

}
.csatlkbtn:hover {
  background: #dddddd46;
  animation-name: animateShadow;
  animation-duration: 0.1s;
  box-shadow: 5px 10px #dddddd46;

}

.frcsatlbtn {
  float:right;
  margin-top: 200px;
  margin-right: 200px;
}

.mcst {
  display: none;
  text-decoration: none;
  color:white;
  background: #dddddd31;
  padding: 15px;
  padding-top: 20px;
  width: 100%;
  font-size:25px;
  text-align: center;
  border-radius: 0px;
  margin-bottom: 20px;
  border: 2px solid #393c3d;
}

.mcst:hover {
  background: #dddddd46;
  animation-name: animateShadow;
  animation-duration: 0.1s;
  box-shadow: 5px 10px #dddddd46;

}

@keyframes animateShadow {
  0% {box-shadow: 1px 1px #dddddd46;}
  100% {box-shadow: 5px 10px #dddddd46;}
}

.fvg {
  margin-bottom: -70px;
}


.flri {
  float:right;
  margin-top:-40px;
}



@media(max-width: 750px){
  .backgr {
    height: 750px;
  }
  .bt {
    font-size: 50px;
  }
  .centdiv{
    width: 80%;
  }
  .button2 {
    width:100%;
  }
  .csatlkbtn {
    display: none;
  }
  .mcst {
    display: block;
  }
  .fvg {
    margin-bottom: -20px;
  }
  footer {
    margin-bottom: -20px;
  }
  .footerbg {
    padding: 20px;
    padding-top:30px;
  }
}

</style>